/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { success, error } from '@/libs/notification';
import router from '@/router';
import {
  AutobacsDeviceState,
  AutobacsDeviceDetailData,
  AutobacsDevicePutData,
  AutobacsDevicePrepareData,
  AutobacsDeviceVerifyData,
  AutobacsDeviceLinkData
} from '@/types/autobacsDevice';
import _ from 'lodash';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    detailData: null,
    putData: null,
    status: 0
  },
  mutations: {
    setDetail(state: AutobacsDeviceState, payload: AutobacsDeviceDetailData) {
      state.detailData = payload;
    },
    setPut(state: AutobacsDeviceState, payload: AutobacsDevicePutData) {
      state.putData = payload;
    },
    setStatus(state: AutobacsDeviceState, payload: number) {
      state.status = payload;
    }
  },
  actions: {
    async fetch(context: any, deviceId: any) {
      await axios
        .get(getBaseUrl() + '/autobacs/device/' + deviceId, {
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setDetail', response.data);
          const putData = _.cloneDeep(response.data);
          putData['id'] = deviceId;
          delete putData.financeId;
          delete putData.shipId;
          delete putData.status;
          delete putData.orderCompanyName;
          delete putData.updateUserName;
          delete putData.updatedAt;
          context.commit('setPut', putData);
          context.commit('setStatus', response.data.status);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status === 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
            router.push('/autobacs/device');
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/autobacs/device');
          } else {
            router.push('/error/server');
          }
        });
    },
    async put(context: any) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .put(
          getBaseUrl() + '/autobacs/device/' + context.state.putData.id,
          {
            deviceId: context.state.putData.deviceId,
            deviceCode: context.state.putData.deviceCode,
            deviceType: context.state.putData.deviceType,
            serialCode: context.state.putData.serialCode,
            firmVersion: context.state.putData.firmVersion,
            mboot: context.state.putData.mboot,
            internalBatteryVoltage:
              context.state.putData.internalBatteryVoltage,
            orderCompanyId: context.state.putData.orderCompanyId,
            note: context.state.putData.note,
            handlingType: context.state.putData.handlingType,
            autobacsOrderNumber: context.state.putData.autobacsOrderNumber
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '端末情報の変更に成功しました。');
          router.push(`/autobacs/device/${context.state.putData.id}`);
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
            router.push('/autobacs/device');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async delete(context: any, userId: string) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .delete(getBaseUrl() + '/autobacs/device/' + userId, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', '端末情報の削除に成功しました。');
          router.push('/autobacs/device');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          }
          if (e.response.status == 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
            return router.push('/autobacs/device');
          }
          if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async prepare(context: any, payload: AutobacsDevicePrepareData) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .patch(
          getBaseUrl() + '/autobacs/device/prepare/' + payload.id,
          {
            serialCode: payload.serialCode,
            deviceCode: payload.deviceCode,
            deviceType: payload.deviceType,
            orderCompanyId: payload.orderCompanyId,
            note: payload.note
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', 'ステータスを準備中に変更しました。');
          loadingInstance.close();
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
            router.push('/autobacs/device');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async verify(context: any, payload: AutobacsDeviceVerifyData) {
      await axios
        .patch(
          getBaseUrl() + '/autobacs/device/verify/' + payload.id,
          {
            firmVersion: payload.firmVersion,
            mboot: payload.mboot,
            internalBatteryVoltage: payload.internalBatteryVoltage
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', 'ステータスを検証済に変更しました。');
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
            router.push('/autobacs/device');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async stop(context: any, deviceId: string) {
      await axios
        .patch(
          getBaseUrl() + '/autobacs/device/stop/' + deviceId,
          {},
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', 'ステータスを使用中止に変更しました。');
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
            router.push('/autobacs/device');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async restart(context: any, deviceId: string) {
      await axios
        .patch(
          getBaseUrl() + '/autobacs/device/restart/' + deviceId,
          {},
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', 'ステータスを検証済に変更しました。');
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
            router.push('/autobacs/device');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async unlink(context: any, deviceId: string) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/autobacs/device/unlink/' + deviceId,
          {},
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '対象端末のリンク解除に成功しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async link(context: any, payload: AutobacsDeviceLinkData) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/autobacs/device/link/' + payload.id,
          {
            autobacsOrderNumber: payload.autobacsOrderNumber
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then(() => {
          success('Success', '対象端末のリンクに成功しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, '対象の端末情報が存在しません。');
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async exchange(
      context: any,
      payload: {
        deviceId: string;
        sim: string;
      }
    ) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(
          getBaseUrl() + '/autobacs/device/sim/exchange/' + payload.deviceId,
          {
            SIMNo: payload.sim
          },
          {
            headers: { auth: await getAccessToken() }
          }
        )
        .then((response) => {
          success('Success', 'sim情報切り替えに成功しました');
          router.push('/autobacs/device/' + response.data.deviceLedgerId);
          loadingInstance.close();
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status == 404) {
            error(e.response.status, e.response.data);
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async setPutData(context: any, payload: AutobacsDevicePutData) {
      context.commit('setPut', payload);
    }
  }
};
